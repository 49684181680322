import React, { useState, useEffect } from "react";

function PatentSearch() {
  const [idea, setIdea] = useState(
    "Systems to reconstruct video concerts in 2d and 3d using the video input from attendees"
  );
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState("");
  const handleSearch = async () => {
    try {
      setCategories([]);
      setResults([]);
      setLoading(true);
      setError(""); // Clear previous errors

      // Make a POST request using fetch
      const response = await fetch(
        "https://us-central1-patentia-432817.cloudfunctions.net/createCPC",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ idea }),
        }
      );
      setLoading(false);
      // Check if the response is okay
      if (!response.ok) {
        throw new Error("Failed to fetch categories. Please try again.");
      }

      // Parse the response data
      const data = await response.json();

      if (data.categories.length > 0) {
        handleSearch2(data.categories);
      }
    } catch (err) {
      console.error("Error fetching categories:", err);
      setError("Failed to fetch categories. Please try again.");
    }
  };

  const handleSearch2 = async (categories) => {
    setLoading(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      const urlencoded = new URLSearchParams();
      urlencoded.append("inputIdea", idea);

      categories.forEach((category) => {
        urlencoded.append("categories", category.code);
      });
      let url = "https://patentia-service-oalijvxdka-uc.a.run.app";
      //let url = "http://localhost:8080";
      const response = await fetch(url, {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
      });
      const data = await response.json();
      setCategories(categories);
      console.log(data.payload);
      setResults(data.payload);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-xxl  mt-5">
      <h1 className="text-center mb-4">Patentia</h1>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          value={idea}
          onChange={(e) => setIdea(e.target.value)}
          placeholder="Enter your search query"
        />
        <button
          className="btn btn-primary"
          onClick={handleSearch}
          disabled={loading}
        >
          {loading ? "Searching..." : "Search"}
        </button>
      </div>

      <div>
        {error && <div className="alert alert-danger mt-3">{error}</div>}
        {categories.length > 0 && <CPCCodes categories={categories} />}
        <br />
        {results.length > 0 ? (
          <>
            <h3 className="text-center mb-4">Patents</h3>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Publication Number</th>
                  <th scope="col">Title</th>
                  <th scope="col">Abstract</th>
                  <th scope="col">Analysis</th>
                  <th scope="col">Risk</th>
                </tr>
              </thead>
              <tbody>
                {results.map((result, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>
                      <a
                        href={result.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {result.publication_number}
                      </a>
                    </td>
                    <td>{result.title}</td>
                    <td>{result.abstract}</td>

                    <td>
                      <RSAnalysis query={idea} abstract={result.abstract} />
                    </td>
                    <td style={{ width: 100 }}>
                      <ProgressBar
                        value={Math.round(result.similarity_percentage)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <p className="text-center">No results found</p>
        )}
      </div>
    </div>
  );
}

const CPCCodes = ({ categories }) => {
  return (
    <div>
      <h3 className="text-center mb-4">Suggested CPC</h3>
      <ul className="list-group">
        {categories.map((category, index) => (
          <li key={index} className="list-group-item">
            <strong>{category.code}:</strong> {category.description}
          </li>
        ))}
      </ul>
    </div>
  );
};
const RSAnalysis = ({ query, abstract }) => {
  const [analysis, setAnalysis] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetch2 = async () => {
    try {
      setLoading(true);
      setError(null); // Limpiar el error anterior
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams();
      urlencoded.append("query", query);
      urlencoded.append("abstract", abstract);

      const response = await fetch(
        "https://rsanalisys-307264015315.us-central1.run.app",
        {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch analysis");
      }

      const data = await response.json();
      setAnalysis(data.description); // Guardar el resultado del análisis
      setError(null); // Limpiar cualquier error existente
    } catch (err) {
      setError("Error fetching analysis. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch2(); // Llamar a fetch2 cuando el componente se monta
  }, [query, abstract]);

  return (
    <div>
      {loading && (
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {error && (
        <div>
          <span>{error}</span>
          <button onClick={fetch2} className="btn btn-primary mt-2">
            Retry
          </button>
        </div>
      )}

      {analysis && (
        <div>
          {/* Muestra el resultado del análisis */}
          <strong>Analysis:</strong> {JSON.stringify(analysis)}
        </div>
      )}
    </div>
  );
};
const ProgressBar = ({ value }) => {
  const colorRisk = (risk) => {
    if (risk > 90) {
      return "badge bg-danger";
    } else if (risk > 75) {
      return "badge bg-warning";
    } else {
      return "badge bg-success";
    }
  };
  return (
    <div className="progress">
      <div
        className={`progress-bar ${colorRisk(value)}`}
        role="progressbar"
        style={{ width: `${value}%` }} // Pasando el valor como un objeto de estilo
        aria-valuenow={value}
        aria-valuemin="0"
        aria-valuemax="100"
      >
        {value}%
      </div>
    </div>
  );
};
export default PatentSearch;
